import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import Avatar from '@northstar/core/Avatar';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/Auth';
import { mutate, useSWRConfig } from 'swr';
import { string, object, mixed } from 'libs/validation';
import { Dropzone } from 'components/Dropzone';
import { useCallback } from 'react';
import { Button } from 'components/Button';
import { MESSAGE_ZIPPING_FILES } from 'constants/file';
import { DraftCommentType, useDraftSave } from 'hooks/useDraftSave';
import { HTMLEditor } from 'components/HTMLEditor';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useFormScrollToError } from 'hooks/useFormScrollToError';
import { DraftStatus } from '../DraftElements/DraftStatus';
import { DraftDeleteButton } from '../DraftElements/DraftDeleteButton';

interface Values {
    description: string;
    attachments?: File[];
}

const maxCharsLimit = 100_000;

const validationSchema = object({
    description: string().trim().min(2).max(maxCharsLimit).required(),
    attachments: mixed().attachments(),
});

interface Props {
    initialDraft?: DraftCommentType;
}

const Form = ({ initialDraft }: Props) => {
    const { cache } = useSWRConfig();
    const { user, updateToken } = useAuth();
    const { addNotification } = useApp();
    const { id } = useParams();
    const commentEndpoint = `/cases/draft/${id}/comment`;
    const {
        draftSaveStatus,
        setDraftSaveStatus,
        draftData,
        setDraftData,
        uploadCommentAttachment,
        files,
        setFiles,
        saveDraftComment,
        deleteDraftComment,
        removeCommentAttachment,
        reuploadCommentAttachment,
        acceptMaxFiles,
    } = useDraftSave(initialDraft, id);

    const formInstance = useForm({
        defaultValues: {
            description: (draftData?.description as any) ?? '',
            attachments: (draftData?.attachments as any) ?? [],
        },
        resolver: useYupValidationResolver(validationSchema),
        mode: 'onChange',
        resetOptions: { keepDefaultValues: true },
    });

    const {
        reset: resetForm,
        setValue: setFieldValue,
        formState: { isSubmitting, errors },
        trigger,
    } = formInstance;

    const onSubmit = async (values: any) => {
        const payload = validationSchema.cast(values) as Values;
        setDraftSaveStatus('isSubmitting');
        try {
            updateToken(-1);
            const { description } = payload;
            const data = await axios.post(`/cases/${id}/comment`, {
                description,
                draftCommentId: draftData?.id,
            });

            if (data.status === 201) {
                addNotification({
                    message: 'Note added successfully',
                    status: 201,
                });
                mutate(
                    `/cases/${id}/activities`,
                    (
                        { activities }: { activities: Array<Comment> } = {
                            activities: [],
                        }
                    ) => ({
                        activities: [data.data, ...activities],
                    }),
                    false
                );
                cache.delete(commentEndpoint);
                resetForm();
                setFiles([]);
                setDraftData(undefined);
            }
        } catch (e: any) {
            const message =
                e?.response?.data?.message || 'An unexpected error occurred';
            addNotification({ message, status: e?.response?.status || 500 });
        } finally {
            setDraftSaveStatus(undefined);
        }
    };

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const newFiles = acceptMaxFiles(acceptedFiles, files);
            setFieldValue('attachments', [...files, ...newFiles] as any);
            await trigger('attachments');
            await uploadCommentAttachment(acceptedFiles);
        },
        [acceptMaxFiles, files, setFieldValue, trigger, uploadCommentAttachment]
    );

    const hnadleRemoveFileDrop = async (file: File) => {
        try {
            await removeCommentAttachment(file);
            setFieldValue('attachments', files, { shouldValidate: true });
        } catch (error) {
            addNotification({ message: 'Failed to remove file', status: 500 });
        }
    };

    const handleChange = async (value: string) => {
        setFieldValue('description', value as any);
        const isValid = await trigger('description');
        if (!isValid && value.length > maxCharsLimit) {
            return;
        }
        saveDraftComment(commentEndpoint, {
            description: value,
        });
    };

    const deleteComment = () => {
        deleteDraftComment();
        resetForm();
    };

    const retryUploadFile = async (file: File) => {
        await reuploadCommentAttachment(file);
        await trigger('attachments');
    };

    const actionDisable = !!(isSubmitting || draftSaveStatus);

    const resetEditorContent = !!(
        initialDraft?.description && draftData === undefined
    );

    const { formFieldRef, scrollToFirstError } = useFormScrollToError();

    return (
        <form
            onSubmit={formInstance.handleSubmit(onSubmit, scrollToFirstError)}
            ref={formFieldRef}
            id="case-comment-form"
        >
            <Box sx={{ display: 'flex', mb: 2 }}>
                <Avatar
                    sx={{
                        width: 36,
                        height: 36,
                        bgcolor: 'primary.main',
                        mr: 2,
                    }}
                    alt={user?.name}
                    src="./images/avatar.jpg"
                />
                <Box sx={{ width: 'calc(100% - 52px)' }}>
                    <HTMLEditor
                        fieldError={errors.description?.message?.toString()}
                        onChange={handleChange}
                        placeholder="Comment on this request..."
                        initialValue={draftData?.description || ''}
                        resetContent={
                            resetEditorContent || draftData === undefined
                        }
                        readonly={
                            isSubmitting || draftSaveStatus === 'deleting'
                        }
                    />
                    <Box sx={{ my: 2 }}>
                        <Dropzone
                            onDrop={onDrop}
                            label="Attach Files"
                            info={MESSAGE_ZIPPING_FILES}
                            disabled={
                                isSubmitting || draftSaveStatus === 'deleting'
                            }
                            errors={errors.attachments?.message?.toString()}
                            files={files}
                            onRemove={hnadleRemoveFileDrop}
                            retryUpload={retryUploadFile}
                        />
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ mt: 2 }}
                        color="text.secondary"
                    >
                        <Button
                            variant="contained"
                            disabled={!!(isSubmitting || !!draftSaveStatus)}
                            isLoading={isSubmitting}
                            type="submit"
                            sx={{
                                mr: 1.5,
                            }}
                        >
                            Submit
                        </Button>
                        <DraftStatus status={draftSaveStatus} />
                        <DraftDeleteButton
                            hasDraft={!!draftData}
                            disabled={actionDisable}
                            onClick={deleteComment}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider />
        </form>
    );
};

export default Form;
