import FormControlLabel from '@northstar/core/FormControlLabel';
import Switch from '@northstar/core/Switch';
import { useTheme } from '@northstar/core/styles';

const ThemeSwitcher = () => {
    const theme = useTheme();
    const { themeToggler } = theme;
    const { mode } = theme.palette;

    return (
        <FormControlLabel
            sx={{
                width: '100%',
                ml: 0,
                justifyContent: 'space-between',
            }}
            control={
                <Switch
                    id="theme-switcher"
                    size="small"
                    color="primary"
                    checked={mode === 'dark'}
                    onChange={themeToggler}
                />
            }
            label="Dark mode"
            labelPlacement="start"
        />
    );
};

export default ThemeSwitcher;
