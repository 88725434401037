import { useState } from 'react';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { Button, Grid, Tooltip } from '@northstar/core';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { withAuth } from 'components/Auth';
import { formatedDate } from 'utils/formatedDate';
import { CaseData, Comment } from 'types/cases';
import { Main } from 'components/layouts';
import { useApp } from 'contexts/App';
import { useAccounts } from 'contexts/Accounts';
import { ActivityItemLoading } from './components/Loading/ActivityItemLoading';
import { Form, ActivityItem, AttachmentList } from './components/View';
import ResolveCaseDialog from './components/View/ResolveCaseDialog';
import { PageBody, PageHeader } from './components/PageContent';
import {
    CaseContentLoading,
    CaseDetailsLoading,
} from './components/Loading/CaseViewLoading';
import { SeverityUpdateDialog } from './components/Dialog/SeverityUpdateDialog';
import { ParticipantsDialog } from './components/Dialog/ParticipantsDialog';
import { ParticipantItem } from './components/View/ParticipantItem';

const ViewCase = () => {
    const { appConfig } = useApp();
    const { getAllAccountsIds } = useAccounts();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [severityDialogOpen, setSeverityDialogOpen] = useState(false);
    const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false);

    const { id } = useParams();

    const { data, error } = useSWR<CaseData>({
        url: `/cases/${id}`,
        args: {
            header: {
                PortalAccountIds: getAllAccountsIds(),
            },
        },
    });

    const { data: activities } = useSWR(data && `/cases/${id}/activities`);

    const { data: draftComment } = useSWR(`/cases/draft/${id}/comment`);

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        {
            href: '/support/requests',
            title: 'Manage Requests',
            isActive: false,
        },
        {
            title: data?.caseNumber || '',
            isActive: true,
            href: '#',
        },
    ];

    const isSupportRequest = data?.category !== 'Managed Service Request';
    const canUpdateSeverity = !data?.resolveDate && isSupportRequest;
    const isResolved =
        data?.resolveDate ||
        data?.caseStatus === 'Resolved' ||
        appConfig?.IS_READ_ONLY_MAINTENANCE;

    return (
        <Main
            breadcrumbs={breadcrumbs}
            breadcrumbsMode="dark"
            errorStatus={error?.response?.status}
        >
            <PageHeader>
                {data && (
                    <>
                        {!isResolved && (
                            <ResolveCaseDialog
                                open={dialogOpen}
                                handleClose={() => setDialogOpen(false)}
                                caseData={data}
                            />
                        )}
                        {canUpdateSeverity && (
                            <SeverityUpdateDialog
                                open={severityDialogOpen}
                                handleClose={() => setSeverityDialogOpen(false)}
                                selected={data.severity}
                                caseId={data.id}
                            />
                        )}
                        <ParticipantsDialog
                            open={participantsDialogOpen}
                            handleClose={() => setParticipantsDialogOpen(false)}
                            caseId={data.id}
                            createdById={data.createdById}
                            existingUsers={data.participants}
                            accountId={data.ownerInfo.accountId}
                            tenantId={data.ownerInfo.tenantId}
                        />
                    </>
                )}
                <Box sx={{ display: 'flex', color: 'common.white' }}>
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{ flexGrow: 1 }}
                    >
                        Review request
                    </Typography>
                    {data && !isResolved && (
                        <Tooltip
                            title={
                                <>
                                    <span>
                                        Has your question already been answered?
                                    </span>
                                    <br />
                                    <span>
                                        Feel free to resolve your request any
                                        time.
                                    </span>
                                </>
                            }
                        >
                            <Button
                                variant="outlined"
                                size="medium"
                                color="inherit"
                                onClick={() => setDialogOpen(true)}
                                aria-label="Resolve Request"
                            >
                                Resolve Request
                            </Button>
                        </Tooltip>
                    )}
                </Box>
            </PageHeader>
            <PageBody>
                <Grid spacing={4}>
                    <Grid item sm={12} md={9}>
                        {data ? (
                            <>
                                <Box id="title-box" sx={{ mb: 4 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 0.5,
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            sx={{ wordBreak: 'break-all' }}
                                        >
                                            {data?.caseTitle}
                                        </Typography>
                                        <Typography
                                            variant="label"
                                            labelColor="primary"
                                            sx={{
                                                maxHeight: '20px',
                                                ml: 1,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {data?.caseStatus}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        component="p"
                                        variant="h6"
                                        color="textSecondary"
                                        gutterBottom
                                        sx={{ fontWeight: 400 }}
                                    >
                                        {data?.caseNumber}
                                    </Typography>
                                </Box>
                                <Box id="desc-box" sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        sx={{ fontWeight: 500 }}
                                    >
                                        Description
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            wordBreak: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {data.caseDescription}
                                    </Typography>
                                </Box>
                                {data?.attachments.length > 0 && (
                                    <Box id="attachments-box" sx={{ mb: 4 }}>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Attachments:
                                        </Typography>

                                        <AttachmentList
                                            data={data?.attachments}
                                            createdAt={data?.createDate}
                                        />
                                    </Box>
                                )}
                                <Box id="activity-section" sx={{ mt: 6 }}>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Activity:
                                    </Typography>
                                    {data && !isResolved && (
                                        <Form initialDraft={draftComment} />
                                    )}
                                    {!activities ? (
                                        <ActivityItemLoading />
                                    ) : (
                                        activities?.activities.map(
                                            (note: Comment) => (
                                                <ActivityItem
                                                    aria-label="activity item"
                                                    key={note.id}
                                                    user={note.createdBy}
                                                    date={note.createdOn}
                                                    details={note.description}
                                                    subject={note.subject}
                                                    sentTo={note.sentTo}
                                                    activityType={
                                                        note.activityType
                                                    }
                                                    attachments={
                                                        note.attachments
                                                    }
                                                    isComment
                                                />
                                            )
                                        )
                                    )}
                                    <ActivityItem
                                        aria-label="request status activity"
                                        date={
                                            data &&
                                            formatedDate(
                                                data.modifyDate
                                            ).toLocalDateString()
                                        }
                                        changedTo={data?.caseStatus}
                                        isComment={false}
                                    />
                                </Box>
                            </>
                        ) : (
                            <CaseContentLoading />
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box
                            id="details-box"
                            sx={{
                                padding: 3,
                                backgroundColor: 'background.default',
                            }}
                        >
                            {data ? (
                                <>
                                    {isSupportRequest && (
                                        <Box marginBottom={3}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                marginBottom={0.5}
                                            >
                                                Severity
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                                sx={{
                                                    cursor: canUpdateSeverity
                                                        ? 'pointer'
                                                        : '',
                                                }}
                                                onClick={() => {
                                                    if (canUpdateSeverity) {
                                                        setSeverityDialogOpen(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                {data?.severity ??
                                                    'Select Severity'}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box marginBottom={3}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            marginBottom={0.5}
                                        >
                                            Product
                                        </Typography>
                                        <Typography variant="body1">
                                            {data?.product}
                                        </Typography>
                                    </Box>
                                    {data?.productVersion && (
                                        <Box marginBottom={3}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                marginBottom={0.5}
                                            >
                                                Version
                                            </Typography>
                                            <Typography variant="body1">
                                                {data?.productVersion}
                                            </Typography>
                                        </Box>
                                    )}
                                    {data?.operatingSystem && (
                                        <Box marginBottom={3}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                marginBottom={0.5}
                                            >
                                                Platform
                                            </Typography>
                                            <Typography variant="body1">
                                                {data?.operatingSystem}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box marginBottom={3}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            marginBottom={0.5}
                                        >
                                            Created On
                                        </Typography>
                                        <Typography variant="body1">
                                            {formatedDate(
                                                data?.createDate
                                            ).toDate()}
                                        </Typography>
                                    </Box>
                                    <Box marginBottom={3}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            marginBottom={0.5}
                                        >
                                            Fortra Assignee
                                        </Typography>
                                        <Typography variant="body1">
                                            {data?.ownerInfo.owner}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            marginBottom={0.5}
                                        >
                                            Shared With
                                        </Typography>
                                        <Box>
                                            <Typography
                                                variant="body1"
                                                marginBottom={1}
                                                paddingX={0.5}
                                            >
                                                {`${data.createdBy} (Creator)`}
                                            </Typography>
                                            {data?.participants?.map((p) => (
                                                <ParticipantItem
                                                    key={p.contactId}
                                                    participant={p}
                                                    caseId={data.id}
                                                />
                                            ))}
                                        </Box>
                                        <Button
                                            disabled={!data.ownerInfo.tenantId}
                                            aria-label="Share participants"
                                            aria-haspopup="dialog"
                                            onClick={() =>
                                                setParticipantsDialogOpen(true)
                                            }
                                        >
                                            + Share
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <CaseDetailsLoading />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </PageBody>
        </Main>
    );
};

export default withAuth(ViewCase);
