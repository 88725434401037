import { TooltipCell } from '@northstar/plus';
import { Cell, ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CaseTitleColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('caseTitle', {
        header: 'Request Title',
        enableHiding: false,
        filterFn: casesDefaultFilterFn,
        cell: ({ cell }: { cell: Cell<CaseData, unknown> }) => {
            const value = cell.getValue() as string;

            return <TooltipCell text={value} />;
        },
        sortingFn: (rowA, rowB, columnId) => {
            const a =
                (rowA.original[columnId as keyof CaseData] as string) || '0';
            const b =
                (rowB.original[columnId as keyof CaseData] as string) || '0';

            // Convert to lowercase for case-insensitive comparison
            const aCopy = a.toLowerCase();
            const bCopy = b.toLowerCase();

            // Custom sorting logic
            if (/^\d+$/.test(aCopy) && /^\d+$/.test(bCopy)) {
                // Both are pure numbers
                return Number(a) - Number(b);
            }
            if (/^\d+$/.test(aCopy)) {
                // Numbers come first
                return -1;
            }
            if (/^\d+$/.test(bCopy)) {
                // Numbers come first
                return 1;
            }
            // String comparison
            return aCopy.localeCompare(bCopy);
        },
    });

export { CaseTitleColumn };
