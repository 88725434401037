import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import { EmailOutlined } from '@northstar/icons';
import { useState } from 'react';
import { useTheme } from '@northstar/core/styles';
import Tabs from '@northstar/core/Tabs';
import Tab from '@northstar/core/Tab';
import Box from '@northstar/core/Box';
import { TabPanel } from 'components/TabPanel';
import AttachmentList from 'pages/Support/components/View/AttachmentList';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@northstar/core';
import SanitizeHTML from 'components/SanitizeHTML';
import { AttachmentFile } from 'types/cases';

export type MailOptions = {
    from: string;
    to: string;
    subject: string;
    description: string;
    createdAt: string;
    attachments?: Array<AttachmentFile>;
    activityType?: string;
};

type Props = MailOptions & {
    onClose: () => void;
};

const MailViewerDialog = ({
    activityType,
    attachments,
    createdAt,
    description,
    from,
    onClose,
    subject,
    to,
}: Props) => {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Dialog
            open
            onClose={onClose}
            maxWidth={false}
            fullWidth
            sx={{
                borderRadius: 2,
                boxShadow: 4,
                '& img': {
                    maxWidth: '100% !important',
                    height: 'auto !important',
                },
            }}
            PaperProps={{
                sx: {
                    minHeight: '80vh',
                    maxHeight: '80vh',
                    maxWidth: '800px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    alignItems: 'center',
                    bgcolor: 'primary.main',
                    color: 'white',
                    display: 'flex',
                    gap: 1,
                }}
            >
                <EmailOutlined fontSize="small" />
                <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontWeight: 'bold' }}
                >
                    {subject}
                </Typography>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box>
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        From:{' '}
                    </Typography>

                    <Typography
                        component="span"
                        sx={{ color: theme.palette.primary.main }}
                    >
                        {from}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        To:{' '}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ color: theme.palette.primary.main }}
                    >
                        {to}
                    </Typography>
                </Box>

                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{
                        position: 'sticky',
                        top: 0,
                        bgcolor: 'background.paper',
                        zIndex: 1,
                        marginTop: 3,
                    }}
                    onChange={(_, tabValue) => setActiveTab(tabValue)}
                >
                    <Tab label="HTML" />
                    <Tab label="Source" />
                    <Tab label="Text" />
                    {attachments && attachments.length > 0 && (
                        <Tab label="Attachments" />
                    )}
                </Tabs>

                <Divider sx={{ fontSize: '15px' }} />

                <Box
                    sx={{
                        overflowY: 'auto',
                        flex: 1,
                        mt: 2,
                    }}
                >
                    <TabPanel
                        sx={{
                            p: 0,
                            m: 0,
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                        value={activeTab}
                        index={0}
                        dir={theme.direction}
                    >
                        <SanitizeHTML html={description} />
                    </TabPanel>
                    <TabPanel
                        sx={{
                            p: 0,
                            m: 0,
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                        value={activeTab}
                        index={1}
                        dir={theme.direction}
                    >
                        {description}
                    </TabPanel>
                    <TabPanel
                        sx={{
                            p: 0,
                            m: 0,
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                        value={activeTab}
                        index={2}
                        dir={theme.direction}
                    >
                        <SanitizeHTML
                            html={description.replace(/<\/?[^>]+(>|$)/g, '')}
                        />
                    </TabPanel>
                    {attachments && attachments.length > 0 && (
                        <TabPanel
                            sx={{
                                p: 0,
                                m: 0,
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                            }}
                            value={activeTab}
                            index={3}
                            dir={theme.direction}
                        >
                            <AttachmentList
                                data={attachments}
                                createdAt={createdAt}
                                activityType={activityType}
                            />
                        </TabPanel>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { MailViewerDialog };
