import Skeleton from '@northstar/core/Skeleton';
import Avatar from '@northstar/core/Avatar';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';

const ActivityItemLoading = () => (
    <Box sx={{ my: 2 }}>
        <Box sx={{ display: 'flex' }}>
            <Box>
                <Skeleton variant="circular">
                    <Avatar
                        sx={{
                            width: 36,
                            height: 36,
                        }}
                    />
                </Skeleton>
            </Box>
            <Box sx={{ ml: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 500, mr: 1 }}>
                        <Skeleton width={200} />
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton width={50} />
                    </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant="body1">
                        <Skeleton width="70%" />
                        <Skeleton width="60%" />
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
);

export { ActivityItemLoading };
