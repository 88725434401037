import AccordionSummary from '@northstar/core/AccordionSummary';
import Typography from '@northstar/core/Typography';
import { ExpandMore as ExpandMoreIcon } from '@northstar/icons';
import { formatedDate } from 'utils/formatedDate';

type Props = { name: string; version: string; releaseDate: string };

const DownloadSummary = ({ name, version, releaseDate }: Props) => (
    <AccordionSummary
        expandIcon={<ExpandMoreIcon aria-label="Toggle accordion" />}
        aria-label="Accordion summary"
        sx={{
            '& .MuiAccordionSummary-content': {
                flexDirection: 'column',
            },
        }}
    >
        <Typography variant="h5" component="h3">
            {name}
        </Typography>
        <Typography variant="h6" component="h4" sx={{ fontWeight: 400 }}>
            Version {version}
            <Typography variant="body2" color="textSecondary" component="span">
                {` - Released: ${formatedDate(releaseDate).toDate()}`}
            </Typography>
        </Typography>
    </AccordionSummary>
);

export { DownloadSummary };
