import { Box, Button, Typography } from '@northstar/core';
import Divider from '@northstar/core/Divider';
import { ContactFooter } from 'components/CallToAction/ContactFooter';
import { Container } from 'components/Container';
import { ExpandMore as ExpandMoreIcon } from '@northstar/icons';
import { Link } from 'components/Link';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { Product } from 'types/products';
import { Main } from 'components/layouts';
import { useState } from 'react';
import { useAuth } from 'contexts/Auth';
import LoadingBox from 'components/LoadingBox';
import AccessRestricted from 'components/DataState/AccessRestricted';
import { Article } from 'types/kbArticles';
import { groupKBArticlesByKey } from './groupKBArticlesByKey';

const KBArticlesPage = () => {
    const initialArticlesCount = 5;
    const { getProductPermissions } = useAuth();
    const { productSlug } = useParams();

    const [limit, setLimit] = useState<{ [keyCategory: string]: number }>({});

    const { data: product, error: productSlugError } = useSWR<Product>(
        `/products/slug/${productSlug}`
    );

    const { permissions, isSecured } = product || {};

    const { canKBArticles } = getProductPermissions(permissions, isSecured);

    const { data: kbArticles, error: kbArticlesError } = useSWR(
        canKBArticles && product && `/kbarticles/product/${product.id}`
    );

    const portalKbArticles = kbArticles?.articles.filter(
        (article: Article) => !article.hideFromSitemap
    );

    const articles = kbArticles
        ? groupKBArticlesByKey(portalKbArticles, 'category')
        : undefined;

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        ...(product
            ? [
                  {
                      href: `/${product.slug}`,
                      title: product.name,
                      isActive: false,
                  },
                  {
                      href: `/${product.slug}/kb-articles`,
                      title: 'Knowledge Base',
                      isActive: true,
                  },
              ]
            : []),
    ];

    const handleSeeMore = (categoryName: string, length: number) => {
        setLimit((prev) => {
            return {
                ...prev,
                [categoryName]: length,
            };
        });
    };

    const errorStatus = (productSlugError || kbArticlesError)?.response?.status;

    return (
        <Main breadcrumbs={breadcrumbs} errorStatus={errorStatus}>
            {canKBArticles === false && <AccessRestricted />}

            {articles && (
                <>
                    <Container sx={{ paddingBottom: { xs: 2, sm: 4 } }}>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {product?.name} Knowledge Base
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h2"
                            color="textSecondary"
                            sx={{ fontWeight: 'normal' }}
                        >
                            Check out our common troubleshooting articles,
                            videos, and how-to guides to make using our software
                            as easy as possible.
                        </Typography>
                    </Container>
                    <Container sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Divider />
                    </Container>
                    <Container sx={{ paddingTop: { xs: 2, sm: 4 } }}>
                        {Object.entries(articles).map(
                            ([categoryName, articleCategory], index) => {
                                return (
                                    <Box
                                        component="section"
                                        key={categoryName}
                                        marginTop={index > 0 ? 6 : 0}
                                    >
                                        <Box marginBottom={2}>
                                            <Box alignItems="center">
                                                <Box>
                                                    <Typography
                                                        variant="h5"
                                                        component="h3"
                                                        gutterBottom
                                                    >
                                                        {
                                                            articleCategory.category
                                                        }
                                                    </Typography>
                                                    {articleCategory.description && (
                                                        <Typography color="textSecondary">
                                                            {
                                                                articleCategory.description
                                                            }
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            {articleCategory.articles
                                                .slice(
                                                    0,
                                                    limit[categoryName] ||
                                                        initialArticlesCount
                                                )
                                                .map((item) => (
                                                    <Box
                                                        marginTop={2}
                                                        marginBottom={2}
                                                        key={
                                                            item.knowledgeArticleId
                                                        }
                                                    >
                                                        <Box
                                                            component={Link}
                                                            paddingY={1}
                                                            to={item.urlKey}
                                                        >
                                                            {item.title}
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </Box>
                                        {articleCategory.articles.length >
                                            initialArticlesCount &&
                                            !limit[categoryName] && (
                                                <Button
                                                    aria-label={`See more ${categoryName} articles`}
                                                    sx={{ ml: -1 }}
                                                    endIcon={<ExpandMoreIcon />}
                                                    onClick={() =>
                                                        handleSeeMore(
                                                            categoryName,
                                                            articleCategory
                                                                .articles.length
                                                        )
                                                    }
                                                >
                                                    See More
                                                </Button>
                                            )}
                                    </Box>
                                );
                            }
                        )}
                    </Container>
                </>
            )}
            {(!product || (canKBArticles && !articles)) && (
                <LoadingBox minHeight={400} />
            )}
            <ContactFooter />
        </Main>
    );
};

export default KBArticlesPage;
