import { Button } from 'components/Button';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { array, object } from 'libs/validation';
import useSWR, { mutate } from 'swr';
import {
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog,
    TextField,
    Autocomplete,
    Typography,
} from '@northstar/core';
import { Controller, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { Participant } from 'types/cases';

interface Props {
    open: boolean;
    handleClose: () => void;
    caseId: string;
    createdById: string;
    existingUsers: Participant[];
    accountId: string;
    tenantId: string | null;
}

interface FormData {
    participants: Participant[];
}

const validationSchema = object({
    participants: array().min(1, 'Must have at least 1 participant'),
});

export const ParticipantsDialog = ({
    open,
    handleClose,
    caseId,
    createdById,
    existingUsers,
    tenantId,
}: Props) => {
    const { data: participants } = useSWR(`/platform_users/${tenantId}`);

    const { addNotification } = useApp();

    const formInstance = useForm({
        defaultValues: {
            participants: [],
        },
        resolver: useYupValidationResolver(validationSchema),
        mode: 'onTouched',
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = formInstance;

    const dialogCloseHandler = (reason?: string) => {
        if (
            (isSubmitting && reason === 'backdropClick') ||
            reason === 'escapeKeyDown'
        ) {
            return false;
        }
        reset();
        handleClose();
        return true;
    };

    const updateParticipants = async (payload: FormData) => {
        try {
            const data = await axios.post('/participants', payload);
            if (data.status === 204) {
                await mutate((key: any) => {
                    if (typeof key === 'object') {
                        return key?.url === `/cases/${caseId}`;
                    }
                    return key === `/cases/${caseId}`;
                });
                await mutate(`/cases/${caseId}/activities`);
                addNotification({
                    message: 'Case shared successfully',
                    status: 204,
                });
                dialogCloseHandler();
            }
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        }
    };

    const onSubmit = handleSubmit(async (values) => {
        const payload = {
            caseId,
            ...values,
        };
        await updateParticipants(payload as any);
    });

    const filterOptions = (options: Participant[], { inputValue }: any) => {
        const filterValue = inputValue.toLowerCase();
        return options.filter(
            (option) =>
                option.displayName?.toLowerCase().includes(filterValue) ||
                option.email?.toLowerCase().includes(filterValue)
        );
    };

    const getParticipants = () => {
        if (!participants) return [];

        const existingUserEmails = new Set(
            existingUsers.map((user) => user.email)
        );

        return participants.filter(
            (p: Participant) =>
                !existingUserEmails.has(p.email) && p.contactId !== createdById
        );
    };

    return (
        <Dialog
            id="participants-dialog"
            open={open}
            onClose={(_, reason) => {
                dialogCloseHandler(reason);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Share this Request</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <Typography variant="subtitle2" marginBottom={2}>
                        Search and select users or enter emails to share:
                    </Typography>
                    <Controller
                        name="participants"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                disableListWrap
                                fullWidth
                                multiple
                                filterOptions={filterOptions}
                                onChange={(_, value) => field.onChange(value)}
                                options={getParticipants()}
                                filterSelectedOptions
                                getOptionLabel={(option: Participant) =>
                                    option.displayName || option.email
                                }
                                renderOption={(props, option) => (
                                    <Typography
                                        component="li"
                                        {...props}
                                        key={option.email}
                                    >
                                        <Typography>
                                            {option.displayName}
                                            <Typography
                                                variant="caption"
                                                display="block"
                                            >
                                                {option.email}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                )}
                                disabled={isSubmitting}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Participant"
                                        error={!!errors?.participants}
                                        helperText={
                                            errors?.participants?.message
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '0px 24px 24px 24px' }}>
                    <Button
                        onClick={() => dialogCloseHandler()}
                        disabled={isSubmitting}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    >
                        Add
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
