import Avatar from '@northstar/core/Avatar';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import AttachmentList from 'pages/Support/components/View/AttachmentList';
import { formatedDate } from 'utils/formatedDate';
import { AttachmentFile } from 'types/cases';
import SanitizeHTML from 'components/SanitizeHTML';
import { getAvatarName } from 'utils/getAvatarName';
import { useState } from 'react';

import { EmailOutlined, ExpandMore } from '@northstar/icons';
import { IconButton, Tooltip } from '@northstar/core';
import { MailOptions, MailViewerDialog } from '../MailViewerDialog';
import { ActivityItemLoading } from '../Loading/ActivityItemLoading';

interface Props {
    user?: string;
    date: string;
    details?: string;
    isComment: boolean;
    changedTo?: string;
    changedFrom?: string;
    attachments?: Array<AttachmentFile>;
    subject?: string;
    createdBy?: string;
    sentTo?: string;
    activityType?: string;
}
const ActivityItem = ({
    user,
    date,
    details,
    isComment,
    changedTo,
    changedFrom,
    attachments,
    activityType,
    subject,
    sentTo,
    ...rest
}: Props) => {
    const [mailOptions, setMailOptions] = useState<MailOptions | null>(null);

    const avatarName = user && getAvatarName(user);
    if (!date) {
        return <ActivityItemLoading />;
    }

    const handleMailOptions = () => {
        setMailOptions({
            from: user ?? '',
            to: sentTo ?? '',
            subject: subject ?? 'Subject Line',
            description: details ?? '',
            attachments,
            createdAt: date,
            activityType,
        });
    };

    return (
        <>
            {mailOptions && (
                <MailViewerDialog
                    {...mailOptions}
                    onClose={() => setMailOptions(null)}
                />
            )}
            {isComment ? (
                <Box
                    sx={{
                        display: 'flex',
                        marginTop: 2,
                    }}
                    {...rest}
                >
                    <Avatar
                        sx={{
                            width: 36,
                            height: 36,
                            bgcolor: 'secondary.main',
                        }}
                        alt={user}
                    >
                        {avatarName}
                    </Avatar>
                    <Box sx={{ ml: 2, flex: 1, width: 0 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: 500, mr: 1 }}
                            >
                                {user}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="span"
                            >
                                {formatedDate(date).toLocalDateString()}
                            </Typography>
                        </Box>
                        {subject && activityType === 'email' && (
                            <Typography variant="body1">
                                Subject: {subject}
                            </Typography>
                        )}
                        {details && (
                            <SanitizeHTML
                                sx={{
                                    my: 1,
                                    overflow: 'hidden',
                                    '& img': {
                                        maxWidth: '100% !important',
                                        height: 'auto !important',
                                    },
                                    '& > *': {
                                        overflow: 'auto',
                                    },
                                }}
                                html={details}
                            />
                        )}
                        <AttachmentList
                            data={attachments}
                            createdAt={date}
                            activityType={activityType}
                        />
                    </Box>
                    {details && subject && activityType === 'email' && (
                        <Box>
                            <Tooltip
                                title="View original email"
                                arrow
                                placement="bottom-end"
                            >
                                <IconButton
                                    color="default"
                                    aria-label="View original email"
                                    onClick={handleMailOptions}
                                >
                                    <ExpandMore fontSize="small" />
                                </IconButton>
                            </Tooltip>

                            <IconButton disabled>
                                <EmailOutlined fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'baseline',
                        marginTop: 2,
                    }}
                    {...rest}
                >
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        Your request status changed{' '}
                        {changedFrom && (
                            <>
                                to{' '}
                                <Box component="span" sx={{ fontWeight: 500 }}>
                                    {changedFrom}
                                </Box>{' '}
                            </>
                        )}
                        to{' '}
                        <Box component="span" sx={{ fontWeight: 500 }}>
                            {changedTo}
                        </Box>
                        .
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {date}
                    </Typography>
                </Box>
            )}
            <Divider sx={{ flexBasis: '100%', marginTop: 2 }} />
        </>
    );
};

export default ActivityItem;
