import { Box, IconButton, Typography } from '@northstar/core';
import { Close } from '@northstar/icons';
import { useState } from 'react';
import { mutate } from 'swr';
import { Participant } from 'types/cases';
import axios from 'utils/axios';

interface Props {
    caseId: string;
    participant: Participant;
}
export const ParticipantItem = ({ participant, caseId }: Props) => {
    const [deleting, setDeleting] = useState(false);

    const handleDeletParticipant = async () => {
        try {
            setDeleting(true);
            await axios.delete('/participants', {
                headers: {
                    skipCancelation: true,
                },
                data: { caseId, participants: [participant] },
            });

            await mutate((key: any) => {
                if (typeof key === 'object') {
                    return key?.url === `/cases/${caseId}`;
                }
                return key === `/cases/${caseId}`;
            });
            await mutate(`/cases/${caseId}/activities`);
        } catch (e) {
            setDeleting(false);
            console.error(e);
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
            padding={0.5}
            sx={{
                ':hover': {
                    backgroundColor: 'action.hover',
                    transition: '.3s ease',
                },
            }}
        >
            <Typography
                variant="body1"
                title={participant.email}
                sx={{ wordWrap: 'break-word', overflow: 'hidden' }}
            >
                {participant.displayName || participant.email}
            </Typography>
            <IconButton
                aria-label="delete paticipent"
                sx={{
                    padding: 0.25,
                }}
                onClick={handleDeletParticipant}
                disabled={deleting}
            >
                <Close
                    sx={{
                        fontSize: 18,
                    }}
                />
            </IconButton>
        </Box>
    );
};
