import { Container } from 'components/Container';
import Paper from '@northstar/core/Paper';
import Button from '@northstar/core/Button';
import { useAuth } from 'contexts/Auth';
import DataState from 'components/DataState';

type Props = {
    productName: string;
};

const LoginBanner = ({ productName }: Props) => {
    const { signIn } = useAuth();

    return (
        <Container
            sx={{
                paddingTop: 5,
                paddingBottom: 15,
            }}
        >
            <Paper
                component={Paper}
                elevation={0}
                variant="outlined"
                sx={{
                    p: 2,
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    maxWidth: 840,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <DataState
                    title="Please log in to continue"
                    description={`To view Knowledge Base articles, product manuals, and
                downloads for ${productName} , you will need to log in to
                your account and have this product associated with your
                account.`}
                    actions={[
                        <Button
                            size="medium"
                            variant="contained"
                            sx={{
                                paddingX: 7,
                            }}
                            onClick={() => signIn()}
                        >
                            LOG IN
                        </Button>,
                    ]}
                />
            </Paper>
        </Container>
    );
};

export default LoginBanner;
