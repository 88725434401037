import { Box, Divider } from '@northstar/core';
import Container from '@northstar/core/Container';
import { withAuth } from 'components/Auth';
import { Main } from 'components/layouts';
import { useAuth } from 'contexts/Auth';
import { UserPermissions } from 'types/permissions';
import { useAccounts } from 'contexts/Accounts';
import { CaseDataResponse } from 'types/cases';
import useSWR from 'swr';
import { useMemo } from 'react';
import LoadingBox from 'components/LoadingBox';
import { Main as MainSection, CasesList } from './components';

const breadcrumbs = [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    {
        title: 'Manage Requests',
        isActive: true,
    },
];

const SupportCases = () => {
    const casesAPI = '/cases';
    const permissionsAPI = '/permissions?service=SUPPORT_CASES';

    const { user } = useAuth();
    const { getAllAccountsIds, relatedAccounts } = useAccounts();

    const {
        data: caseData,
        error,
        mutate: mutateCases,
        isLoading: isLoadingCases,
        isValidating: isValidatingCases,
    } = useSWR<CaseDataResponse>(
        relatedAccounts && {
            url: casesAPI,
            args: { header: { portalAccountIds: getAllAccountsIds() } },
        }
    );

    const {
        data: draftCaseData,
        mutate: mutateDraftCases,
        isLoading: isLoadingDraftCases,
        isValidating: isValidatingDraftCases,
    } = useSWR<CaseDataResponse>(
        relatedAccounts && {
            url: '/cases/draft',
            args: { header: { portalAccountIds: getAllAccountsIds() } },
        }
    );

    const errorStatus = !user?.is_portal_user ? 403 : error?.response?.status;

    const { handleResourcePermissions, mergedPermissionProducts } =
        useAccounts();

    useSWR<UserPermissions>(
        user?.is_portal_user && !mergedPermissionProducts && permissionsAPI,
        { onSuccess: handleResourcePermissions }
    );

    const combinedCases = useMemo(() => {
        if (!draftCaseData && !caseData) return [];

        const firstCases = draftCaseData?.cases ? draftCaseData.cases : [];
        const secondCases = caseData?.cases ? caseData.cases : [];

        return [...firstCases, ...secondCases];
    }, [draftCaseData, caseData]);

    return (
        <Main
            breadcrumbFullWidth
            breadcrumbs={breadcrumbs}
            errorStatus={errorStatus}
        >
            <MainSection />
            <Box bgcolor="alternate.main">
                <Divider />
                <Box sx={{ pt: 4, pb: 6 }}>
                    <Container
                        maxWidth="xl"
                        sx={{
                            paddingY: { xs: 2, sm: 4, md: 6 },
                        }}
                    >
                        {relatedAccounts === undefined ? (
                            <LoadingBox
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                }}
                            />
                        ) : (
                            <CasesList
                                caseData={combinedCases}
                                isFetching={
                                    isValidatingCases || isValidatingDraftCases
                                }
                                isLoading={
                                    isLoadingCases || isLoadingDraftCases
                                }
                                mutateCases={mutateCases}
                                mutateDraftCases={mutateDraftCases}
                                productsList={mergedPermissionProducts}
                            />
                        )}
                    </Container>
                </Box>
            </Box>
        </Main>
    );
};

export default withAuth(SupportCases);
