import Paper from '@northstar/core/Paper';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { formatBytes } from 'utils/formatBytes';
import { formatedDate } from 'utils/formatedDate';
import { getFileExtension } from 'utils/getFileExtension';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { useState } from 'react';
import { downloadBase64 } from 'utils/downloadBase64';
import { openFileInANewTab } from 'utils/openFileInANewTab';
import { imageFormats, documentFormats, videoFormats } from 'constants/file';
import { readBase64File } from 'utils/readBase64File';
import { generateHexString } from 'utils/generateHexString';
import { AttachmentType } from 'pages/Support/components/View/AttachmentType';

type Props = {
    altText: string;
    fileName: string;
    fileSize: string;
    uploadDate: string;
    id: string;
    activityType?: string;
};

const Attachment = ({
    fileName,
    fileSize,
    uploadDate,
    altText,
    id,
    activityType,
}: Props) => {
    const date = formatedDate(uploadDate);
    const [actionsDisabled, setActionsDisabled] = useState<boolean>(false);

    const fileExtension = getFileExtension(fileName);

    const { addNotification, closeSnackbar } = useApp();

    const handleAttachmentData = async (data: any, name: string) => {
        const { mimeType, file } = data;
        const base64File = readBase64File(file, mimeType);

        name === 'Downloading'
            ? await downloadBase64(base64File, fileName)
            : await openFileInANewTab(base64File, fileName);
    };

    const finalizeActions = (key: string) => {
        setActionsDisabled(false);
        closeSnackbar(key);
    };

    const getAttachmentData = async (name: string) => {
        setActionsDisabled(true);
        const key = generateHexString();
        addNotification(
            {
                message: `${name}...`,
                status: 0,
            },
            {
                persist: true,
                key,
            }
        );

        try {
            const { data } = await axios(`/attachments/${id}`, {
                headers: {
                    activityType,
                },
            });
            handleAttachmentData(data, name);
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response?.status || 400,
            });
        } finally {
            finalizeActions(key);
        }
    };

    return (
        <Paper
            variant="outlined"
            elevation={0}
            aria-label="attachment block"
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
            }}
        >
            <AttachmentType fileExtension={fileExtension} altText={altText} />
            <Box aria-label="attachment contnet" sx={{ pr: 2, pl: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    <Typography
                        variant="body2"
                        gutterBottom
                        sx={{
                            fontWeight: 500,
                            mr: 1,
                            whiteSpace: 'nowrap',
                            maxWidth: 130,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        title={fileName}
                    >
                        {fileName}
                    </Typography>
                    <Typography variant="body2">
                        {formatBytes(Number(fileSize)).toString()}
                    </Typography>
                </Box>
                {date && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                    >
                        {date.toAddedAt()}
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', sm: 'start' },
                    }}
                >
                    {[
                        ...imageFormats,
                        ...documentFormats,
                        ...videoFormats,
                    ].includes(fileExtension) && (
                        <>
                            <Typography
                                aria-label="View attachment"
                                variant="body2"
                                sx={{
                                    '&:hover': {
                                        cursor: actionsDisabled
                                            ? 'default'
                                            : 'pointer',
                                    },
                                    color: '#006A56',
                                }}
                                onClick={(e: any) =>
                                    actionsDisabled
                                        ? e.preventDefault()
                                        : getAttachmentData('Preparing')
                                }
                            >
                                View
                            </Typography>
                            <Typography variant="body2" sx={{ px: 1 }}>
                                &#8226;
                            </Typography>
                        </>
                    )}
                    <Typography
                        aria-label="Download attachment"
                        variant="body2"
                        sx={{
                            '&:hover': {
                                cursor: actionsDisabled ? 'default' : 'pointer',
                            },
                            color: '#006A56',
                        }}
                        onClick={(e: any) =>
                            actionsDisabled
                                ? e.preventDefault()
                                : getAttachmentData('Downloading')
                        }
                    >
                        Download
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default Attachment;
