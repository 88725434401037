import Grid from '@northstar/core/Grid';
import { AttachmentFile } from 'types/cases';
import Attachment from './Attachment';

interface Props {
    data: AttachmentFile[] | undefined;
    createdAt: string;
    activityType?: string;
}

const AttachmentList = ({ data, createdAt, activityType }: Props) => {
    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {data.map((item: AttachmentFile) =>
                Number(item.fileSize) === 0 ? null : (
                    <Grid item xs={12} md={6} key={item.id}>
                        <Attachment
                            altText={item.fileName}
                            fileName={item.fileName}
                            fileSize={item.fileSize}
                            uploadDate={createdAt}
                            activityType={activityType}
                            id={item.id}
                        />
                    </Grid>
                )
            )}
        </Grid>
    );
};

export default AttachmentList;
