import Box, { BoxProps } from '@northstar/core/Box';
import Typography, { TypographyProps } from '@northstar/core/Typography';
import { SvgIconProps } from '@northstar/core/SvgIcon';
import { Fragment } from 'react';

interface Props extends BoxProps {
    title: string;
    description?: string;
    icon?: React.ElementType;
    IconProps?: SvgIconProps;
    TitleProps?: TypographyProps;
    DescriptionProps?: TypographyProps;
    actions?: React.JSX.Element[];
}

const DataState = (props: Props) => {
    const {
        title,
        description,
        icon,
        IconProps = {},
        actions = [],
        sx: mainSx,
        TitleProps = {},
        DescriptionProps = {},
        ...rest
    } = props;
    const { sx, ...restIconProps } = IconProps;
    const Icon = icon || Box;
    return (
        <Box
            id="data-state-box"
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column',
                textAlign: 'center',
                ...mainSx,
            }}
            {...rest}
        >
            {icon && (
                <Icon
                    color="primary"
                    sx={{
                        marginBottom: 2,
                        width: 80,
                        height: 80,
                        padding: 2,
                        backgroundColor: '#c6efe3',
                        borderRadius: '50%',
                        ...sx,
                    }}
                    {...restIconProps}
                />
            )}
            <Typography
                variant="h4"
                component="p"
                fontWeight={500}
                {...TitleProps}
            >
                {title}
            </Typography>
            {description && (
                <Typography
                    color="textSecondary"
                    marginTop={2}
                    {...DescriptionProps}
                >
                    {description}
                </Typography>
            )}
            {actions.length > 0 && (
                <Box
                    sx={{
                        marginTop: 2,
                        '& > :not(:last-child)': { marginRight: 2 },
                    }}
                    role="group"
                    aria-label="button group"
                >
                    {actions?.map((item, index) => (
                        <Fragment key={index}>{item}</Fragment>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default DataState;
