import { GlobalStyles } from '@northstar/core';

type Props = {
    isError: boolean;
    readonly: boolean;
};

const InjectGlobalStyles = ({ isError, readonly }: Props) => (
    <GlobalStyles
        styles={({ palette, shape }) => ({
            '.jodit-popup__content, .jodit-dialog__panel': {
                backgroundColor: palette.background.paper,
                color: palette.text.primary,
            },
            '.jodit-toolbar-button__text': {
                color: palette.text.primary,
            },
            '.jodit-toolbar-button__button:hover': {
                backgroundColor: `${palette.action.hover} !important`,
            },
            '.jodit-ui-button_variant_primary': {
                backgroundColor: palette.primary.main,
                '&:focus': {
                    backgroundColor: `${palette.primary.main} !important`,
                },
                '&:hover': {
                    backgroundColor: `${palette.primary.dark} !important`,
                },
                '& .jodit-ui-button__text': {
                    color: palette.secondary.contrastText,
                },
            },
            '.jodit-dialog__content, .jodit-dialog__header-title, .jodit-ui-button__text':
                {
                    color: palette.text.primary,
                },
            '.jodit-toolbar__box > div': {
                backgroundColor: `${palette.background.paper} !important`,
            },
            '.jodit-placeholder': {
                color: palette.text.secondary,
            },
            '.jodit-dialog__resizer': {
                display: 'none !important',
            },
            '.jodit-toolbar-button__trigger': {
                opacity: '1 !important',
            },
            '.jodit-toolbar-button__button svg, .jodit-toolbar-button__trigger svg':
                {
                    fill: palette.action.active,
                },
            '.jodit-toolbar-button__button[disabled] svg': {
                fill: palette.action.disabled,
            },
            '.jodit-toolbar-button:hover': {
                borderColor: `${palette.divider} !important`,
            },
            '.jodit-toolbar-button > *': {
                '&:hover': {
                    backgroundColor: `${palette.action.hover} !important`,
                },
            },
            '.jodit-toolbar__box': {
                borderBottom: `1px solid ${palette.divider} !important`,
                borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0 0 !important`,
            },
            '.jodit-ui-input__input': {
                backgroundColor: `${palette.background.paper}`,
                color: `${palette.text.primary}`,
            },
            '.jodit-ui-input_focused_true .jodit-ui-input__wrapper': {
                borderColor: `${palette.primary.main} !important`,
            },
            '.jodit-ui-separator': {
                borderRight: `1px solid ${palette.divider} !important`,
            },
            '.jodit-ui-button_variant_initial:hover': {
                backgroundColor: `${palette.action.hover} !important`,
            },
            '.jodit-wysiwyg': {
                backgroundColor: `${palette.background.paper} !important`,
                color: `${
                    readonly ? palette.text.disabled : palette.text.primary
                } !important`,
            },
            '.jodit-container': {
                border: `1px solid ${
                    isError ? palette.error.main : palette.divider
                } !important`,
            },

            '.jodit-toolbar-button__button[disabled]': {
                opacity: 1,
            },
            '.jodit-wysiwyg > p': {
                margin: 0,
            },
            '.jodit-toolbar-editor-collection:after': {
                backgroundColor: 'unset !important',
            },
            '.jodit-ui-group__brushCell': {
                display: 'none',
            },
        })}
    />
);

export { InjectGlobalStyles };
