import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import LinearProgress from '@northstar/core/LinearProgress';
import Alert from '@northstar/core/Alert';
import { formatBytes } from 'utils/formatBytes';
import { Close as CloseIcon, Replay as ReplayIcon } from '@northstar/icons';
import IconButton from '@northstar/core/IconButton';
import { useState } from 'react';

interface Props {
    file: any;
    onRemove: (file: File) => void;
    hasProgressBar?: boolean;
    disabled?: boolean;
    retryUpload?: (file: File) => void;
}

const DropzoneFile = ({
    file,
    onRemove,
    hasProgressBar,
    disabled,
    retryUpload,
}: Props) => {
    const name = file.name || file.fileName;
    const size = file.size || file.fileSize;
    const progress = file?.progress || 0;
    const uploaded = file?.uploaded;
    const [deleting, setDeleting] = useState(false);
    const disabledOpacity = deleting ? 0.7 : 1;

    const deleteFile = () => {
        onRemove(file);
        setDeleting(true);
        setTimeout(() => {
            if (file.status === 'error') {
                setDeleting(false);
            }
        }, 1000);
    };

    return (
        <Alert
            severity={file?.status || 'success'}
            icon={false}
            action={
                <Box display="flex">
                    <Box width={28}>
                        {uploaded === 'failed' && retryUpload && (
                            <IconButton
                                aria-label="retry upload file"
                                color="inherit"
                                size="small"
                                disabled={disabled}
                                onClick={() => {
                                    retryUpload(file);
                                }}
                            >
                                <ReplayIcon fontSize="inherit" />
                            </IconButton>
                        )}
                    </Box>
                    <IconButton
                        aria-label="remove file"
                        color="inherit"
                        size="small"
                        disabled={deleting || disabled}
                        onClick={deleteFile}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            }
            sx={{
                mt: 2,
                '.MuiAlert-message': {
                    width: '100%',
                },
                opacity: disabledOpacity,
            }}
        >
            <Box display="flex">
                <Box
                    title={name}
                    component="span"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textWrap: 'nowrap',
                        marginRight: 0.5,
                    }}
                >
                    {name}
                </Box>
                <Box component="span" flex="none">
                    - {formatBytes(size).toString()}
                </Box>
            </Box>
            {hasProgressBar && (
                <Box sx={{ width: 1 }}>
                    <Box display="flex" alignItems="center">
                        <Box sx={{ width: 1, mr: 1 }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                            />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="textSecondary">
                                {`${progress}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
            {file.errorMessage && <Typography>{file.errorMessage}</Typography>}
        </Alert>
    );
};

export default DropzoneFile;
