import { useAccountsProvide } from 'contexts/Accounts/useAccountsProvide';
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
} from 'react';
import { AccountDetails, UserAccountsProducts } from 'types/accounts';
import { PermissionsProducts } from 'types/cases';
import { ProductPermission, UserPermissions } from 'types/permissions';
import { UserProducts } from 'types/products';

export type AccountsContextType = {
    getAllAccountsIds: () => string[];
    handleResourcePermissions: (data: UserPermissions) => void;
    mergedPermissionProducts: ProductPermission[] | undefined;
    handleUsersProducts: (data: UserProducts) => void;
    isSingleAccount: boolean;
    myProducts: UserAccountsProducts | undefined;
    permissionsProducts: PermissionsProducts;
    selectedAccounts: string[];
    setSelectedAccounts: Dispatch<SetStateAction<string[]>>;
    userAccountsDetails: AccountDetails[];
    relatedAccounts: AccountDetails[] | undefined;
};

export const accountContextDefaultValues: AccountsContextType = {
    getAllAccountsIds: () => [],
    handleResourcePermissions: () => undefined,
    mergedPermissionProducts: undefined,
    handleUsersProducts: () => undefined,
    isSingleAccount: true,
    myProducts: {},
    permissionsProducts: {},
    selectedAccounts: [],
    setSelectedAccounts: () => [],
    userAccountsDetails: [],
    relatedAccounts: [],
};

const context = createContext<AccountsContextType>(accountContextDefaultValues);

export function AccountsProvider(props: Readonly<{ children?: ReactNode }>) {
    const ctx = useAccountsProvide();
    return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

export const useAccounts = () => {
    const contextValue = useContext(context);

    if (!context) {
        throw new Error(
            'useAccounts must be called from within an AccountsProvider'
        );
    }

    return contextValue;
};
