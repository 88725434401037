import { Button } from 'components/Button';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { object, string } from 'libs/validation';
import useSWR, { mutate } from 'swr';
import { useState } from 'react';
import {
    DialogActions,
    FormControl,
    FormHelperText,
    DialogTitle,
    DialogContent,
    Dialog,
    TextField,
} from '@northstar/core';
import { Controller, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import LoadingBox from 'components/LoadingBox';
import { Severity } from 'types/cases';
import { severityRequiresConfirmation } from 'pages/Support/utils/severityRequiresConfirmation';
import { SeverityConfirmeContnet } from './SeverityConfirmeContnet';
import { SeverityOptions } from './SeverityOptions';

interface Props {
    open: boolean;
    handleClose: () => void;
    selected: string;
    caseId: string;
}

interface FormData {
    currentLevel: string;
    comment: string;
}

const validationSchema = object({
    currentLevel: string().required(),
    comment: string().required().trim().max(100000),
});

export const SeverityUpdateDialog = ({
    open,
    handleClose,
    selected,
    caseId,
}: Props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { data: severityLevels } = useSWR<Severity[]>('/severity_levels');

    const { addNotification } = useApp();
    const resolver = useYupValidationResolver(validationSchema);

    const formInstance = useForm({
        defaultValues: {
            currentLevel: selected || '',
            comment: '',
        },
        resolver,
        mode: 'onTouched',
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        watch,
    } = formInstance;

    const watchCurrentLevel = watch('currentLevel');

    const dialogCloseHandler = (reason?: string) => {
        if (
            (isSubmitting && reason === 'backdropClick') ||
            reason === 'escapeKeyDown'
        ) {
            return false;
        }
        reset();
        handleClose();
        setTimeout(() => setShowConfirmation(false), 300);
        setConfirm(false);
        return true;
    };

    const updateSeverity = async (payload: FormData) => {
        try {
            const data = await axios.put(
                `/cases/update_severity/${caseId}`,
                payload
            );
            if (data.status === 204) {
                await mutate((key: any) => {
                    if (typeof key === 'object') {
                        return key?.url === `/cases/${caseId}`;
                    }
                    return key === `/cases/${caseId}`;
                });
                await mutate(`/cases/${caseId}/activities`);
                addNotification({
                    message: 'Severity updated successfully',
                    status: 204,
                });
                reset({
                    currentLevel: payload.currentLevel,
                    comment: '',
                });
                dialogCloseHandler();
            }
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        }
    };

    const onSubmit = handleSubmit(async (values: any) => {
        const payload = {
            ...values,
            previousLevel: selected,
        };

        if (payload.currentLevel === selected) {
            addNotification({
                message:
                    'The severity level can`t be the same as the previous level',
                status: 0,
            });
            return;
        }

        const willConfirme = severityRequiresConfirmation(
            severityLevels || [],
            values.currentLevel
        );

        if (willConfirme && !showConfirmation) {
            setShowConfirmation(true);
            return;
        }
        if (willConfirme && showConfirmation && !confirm) {
            return;
        }
        await updateSeverity(payload);
    });

    return (
        <Dialog
            id="severity-dialog"
            open={open}
            onClose={(_, reason) => {
                dialogCloseHandler(reason);
            }}
            maxWidth={false}
        >
            <DialogTitle sx={{ paddingBottom: 0 }}>
                {showConfirmation ? 'Contact Support' : 'Update Severity'}
            </DialogTitle>
            {!severityLevels && (
                <LoadingBox
                    sx={{
                        margin: 6,
                        minWidth: 348,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                />
            )}

            <form onSubmit={onSubmit}>
                {!showConfirmation && severityLevels && (
                    <DialogContent sx={{ maxWidth: 960 }}>
                        <Controller
                            name="currentLevel"
                            control={control}
                            defaultValue={selected as any}
                            render={({ field }) => (
                                <FormControl error={!!errors.currentLevel}>
                                    {errors.currentLevel && (
                                        <FormHelperText>
                                            {errors.currentLevel?.message}
                                        </FormHelperText>
                                    )}
                                    <SeverityOptions
                                        {...field}
                                        options={severityLevels}
                                    />
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    margin="dense"
                                    id="sevComment"
                                    label="Comment *"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    error={!!errors?.comment}
                                    helperText={errors.comment?.message}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        />
                    </DialogContent>
                )}
                {showConfirmation && (
                    <DialogContent sx={{ maxWidth: 444 }}>
                        <SeverityConfirmeContnet
                            confirm
                            onConfirme={() => setConfirm((prev) => !prev)}
                        />
                    </DialogContent>
                )}
                <DialogActions sx={{ padding: '0px 24px 24px 24px' }}>
                    <Button
                        onClick={() => dialogCloseHandler()}
                        disabled={isSubmitting}
                    >
                        CANCEL
                    </Button>
                    {showConfirmation && severityLevels && (
                        <Button
                            onClick={() => {
                                setShowConfirmation(false);
                                setConfirm(false);
                            }}
                            disabled={isSubmitting}
                        >
                            BACK
                        </Button>
                    )}
                    {severityLevels && (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={
                                String(watchCurrentLevel) === selected ||
                                isSubmitting ||
                                (showConfirmation && !confirm)
                            }
                            isLoading={isSubmitting}
                        >
                            UPDATE
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};
