import useSWR, { mutate } from 'swr';
import Typography from '@northstar/core/Typography';
import Divider from '@northstar/core/Divider';
import Grid from '@northstar/core/Grid';
import MenuItem from '@northstar/core/MenuItem';
import LoadingBox from 'components/LoadingBox';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect, Suspense, useMemo } from 'react';
import { Dropzone } from 'components/Dropzone';
import { Button } from 'components/Button';
import { UserPermissions } from 'types/permissions';
import { Case, CaseData, Severity } from 'types/cases';
import { MESSAGE_ZIPPING_FILES } from 'constants/file';
import { useAuth } from 'contexts/Auth';
import { useAccounts } from 'contexts/Accounts';
import { useDraftSave } from 'hooks/useDraftSave';
import { object, ObjectSchema } from 'libs/validation';
import { useServiceRequest } from 'hooks/useServiceRequest';
import { Languages, LookupResponse } from 'types/createCase';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFormScrollToError } from 'hooks/useFormScrollToError';
import { defaultValues } from 'pages/Support/components/Create/defaultValues';
import { FormFieldLoading } from 'components/FormFieldLoading';
import {
    LazyFormInput,
    LazyFormSelect,
    LazyFormTextArea,
} from 'components/FormFields';
import { FormControl, FormHelperText } from '@northstar/core';
import { findDefaultSeverity } from 'pages/Support/utils/findDefaultSeverity';
import { KBArticlesSuggestion } from './KBArticlesSuggestion';
import { DraftStatus } from '../DraftElements/DraftStatus';
import { DraftDeleteButton } from '../DraftElements/DraftDeleteButton';
import { InputTitle } from './InputTitle';
import validationSchema from './validationSchema';
import { ProductSelection } from './ProductSelection';
import { getQuestionAnswerReqData } from './getQuestionAnswerReqData';
import { defaultCaseValues } from './defaultCaseValues';
import { SeveritySelectDialog } from '../Dialog/SeveritySelectDialog';
import { ConditionalFormFields } from './ConditionalFormFields';

const Form = () => {
    const { hash } = useLocation();
    const draftId = hash.slice(1);
    const { data: draftCase, isLoading } = useSWR(
        draftId && `/cases/draft/${draftId}`
    );

    const {
        userAccountsDetails,
        isSingleAccount,
        relatedAccounts,
        permissionsProducts,
        selectedAccounts,
        mergedPermissionProducts,
        handleResourcePermissions,
    } = useAccounts();

    const navigate = useNavigate();
    const { questionCriteria, questionDefinitions, answerOptions } =
        useServiceRequest();
    const [formValidationSchema, setFormValidationSchema] = useState<
        ObjectSchema<any>
    >(object(validationSchema));
    const [lookUpOptions, setLookUpOptions] = useState<LookupResponse[]>([]);
    const [openSeverity, setOpenSeverity] = useState(false);
    const [showConditionalFields, setShowConditionalFields] =
        useState<boolean>(true);

    const { addNotification } = useApp();
    const { updateToken, user } = useAuth();
    const {
        saveDraftCase,
        deleteDraftCase,
        draftSaveStatus,
        setDraftSaveStatus,
        draftData,
        setDraftData,
        files,
        setNewDraft,
        setFiles,
        acceptMaxFiles,
        uploadCaseAttachment,
        removeCaseAttachment,
        reuploadCaseAttachment,
    } = useDraftSave(draftCase);

    const { data: languages } = useSWR<Languages[]>('/languages');

    const blockedExtensions = sessionStorage.getItem('extensions');

    useSWR(!blockedExtensions && user && '/file_extensions', {
        onSuccess: (data) => {
            sessionStorage.setItem('extensions', data.extensions);
        },
    });

    useSWR<UserPermissions>(
        !mergedPermissionProducts && '/permissions?service=SUPPORT_CASES',
        {
            onSuccess: handleResourcePermissions,
        }
    );

    const { data: severityLevels } = useSWR<Severity[]>('/severity_levels');
    const defaultSeverityLevel = findDefaultSeverity(
        severityLevels || []
    )?.level;

    const resolver = useYupValidationResolver(formValidationSchema);

    const formInstance = useForm({
        defaultValues,
        resolver,
        values: defaultCaseValues({
            draftCase,
            selectedAccounts,
            languages,
            severityLevels,
        }),
        mode: 'onTouched',
        resetOptions: { keepDefaultValues: true },
    });

    const {
        getValues: getFormValues,
        reset: resetForm,
        setValue: setFieldValue,
        trigger,
        formState: { isSubmitting, errors },
    } = formInstance;

    const formValues: Case = getFormValues();

    const onSubmit: SubmitHandler<Case> = async (val) => {
        const payload = formValidationSchema.cast(val) as any;
        setDraftSaveStatus('isSubmitting');
        let responseData: any = {};
        try {
            const {
                caseTitle,
                caseDescription,
                productId,
                productVersion,
                operatingSystem,
                language,
                selectedAccount,
                draftCaseId,
                severity,
                ...restFormFields
            } = payload;
            const hasAttachment = !!payload.attachments.length;

            const caseQuestionAnswerResponses = getQuestionAnswerReqData({
                answerOptions,
                formFields: restFormFields,
                lookUpOptions,
                questionCriteria,
                questionDefinitions,
            });

            const formData = {
                ...(operatingSystem && { operatingSystem }),
                ...(productVersion && { productVersion }),
                caseDescription,
                caseQuestionAnswerResponses,
                caseTitle,
                language,
                product: productId,
                draftCaseId,
                hasAttachment,
                severity,
            };

            await updateToken(-1);

            responseData = await axios.post('/cases', formData, {
                headers: {
                    portalAccountIds: selectedAccount,
                },
            });

            if (responseData.status === 201) {
                mutate(
                    '/cases',
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => ({
                        cases: [responseData.data, ...cases],
                    }),
                    false
                );
                mutate(
                    '/cases/draft',
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => ({
                        cases: cases.filter((c) => c.id !== draftData?.id),
                    }),
                    false
                );
                setDraftData(undefined);
                setFiles([]);
                addNotification({
                    message: 'Support request opened successfully',
                    status: 201,
                });
            }
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        } finally {
            setDraftSaveStatus(undefined);
            if (responseData?.status === 201) {
                setTimeout(() => {
                    navigate('/support/requests');
                });
            }
        }
    };

    const currentProductList = permissionsProducts[formValues.selectedAccount];

    const selectedUserProducts = useMemo(() => {
        return currentProductList
            ? currentProductList.filter((item) => item.isShowOnPortal)
            : undefined;
    }, [currentProductList]);

    const getSelectedProduct = (softwareProductId: string) =>
        selectedUserProducts?.find(
            (v: any) => v.softwareProductId === softwareProductId
        );

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            setFieldValue(
                'attachments',
                [...files, ...acceptMaxFiles(acceptedFiles, files)],
                { shouldValidate: true }
            );
            await trigger('attachments');
            const caseId = await uploadCaseAttachment(
                acceptedFiles,
                formValues
            );
            setFieldValue('draftCaseId', caseId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [files, formValues]
    );

    const hnadleRemoveFileDrop = async (file: File) => {
        await removeCaseAttachment(file);
        setFieldValue('attachments', files, { shouldValidate: true });
    };

    const retryUploadFile = async (file: File) => {
        const caseId = await reuploadCaseAttachment(file, formValues);
        setFieldValue('draftCaseId', caseId);
        await trigger('attachments');
    };

    const getOrganizationName = (accountId: string) => {
        const foundedItem = userAccountsDetails.find(
            (innerItem) => innerItem.accountId === accountId
        );

        return foundedItem?.accountName || '';
    };

    const updateFormValidation = (removedFields: any, validation: any) => {
        const updatedValidation = formValidationSchema
            .omit(removedFields)
            .concat(object(validation));

        setFormValidationSchema(updatedValidation);
    };

    const handleDraftChange = async (data: any) => {
        const { name, value } = data;

        let newValue;
        const selectedAccount =
            name === 'selectedAccount' ? value : formValues.selectedAccount;
        let removeProduct;

        if (name === 'selectedAccount') {
            setFieldValue('productId', '');
            setFieldValue('product', '');
            removeProduct = {
                productId: '',
                product: '',
            };
        }

        if (name === 'productId') {
            setFieldValue('product', value?.productName);
            newValue = {
                productId: value?.softwareProductId,
                product: value?.productName,
            };
        } else {
            newValue = { [name]: value };
        }

        saveDraftCase({
            ...formValues,
            ...removeProduct,
            ...newValue,
            account: getOrganizationName(selectedAccount),
        });
        setFieldValue('draftCaseId', draftData?.id);
    };

    const deleteDraft = async () => {
        const data = await deleteDraftCase();
        if (data?.status === 204) {
            resetForm();
            setTimeout(() => {
                navigate('/support/requests');
            });
        }
    };

    const actionDisable = !!(isSubmitting || draftSaveStatus);

    useEffect(() => {
        if (!window.location.hash && draftData?.id) {
            resetForm({
                selectedAccount: selectedAccounts[0],
                language: languages?.[0].value,
            });
            setDraftData(undefined);
            setFiles([]);
            setNewDraft({
                isNew: true,
                attempts: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.hash, selectedAccounts, languages]);

    const { formFieldRef, scrollToFirstError } = useFormScrollToError();

    return (
        <FormProvider {...formInstance}>
            <form
                onSubmit={formInstance.handleSubmit(
                    onSubmit,
                    scrollToFirstError
                )}
                ref={formFieldRef}
            >
                {relatedAccounts && !isLoading ? (
                    <Grid container spacing={3}>
                        {!isSingleAccount && (
                            <Grid item xs={12}>
                                <Suspense fallback={<FormFieldLoading />}>
                                    <LazyFormSelect
                                        fullWidth
                                        handleOnChange={handleDraftChange}
                                        id="selectedAccount-filter-select"
                                        inputLabel="Organization"
                                        labelId="selectedAccount-filter-select"
                                        name="selectedAccount"
                                        placeholder="Select Organization *"
                                        renderValue={(value: any) =>
                                            value === '' ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        opacity: 0.5,
                                                        fontSize: '1rem',
                                                    }}
                                                >
                                                    Organization *
                                                </Typography>
                                            ) : (
                                                getOrganizationName(value)
                                            )
                                        }
                                    >
                                        {userAccountsDetails.map((item) => (
                                            <MenuItem
                                                value={item.accountId}
                                                key={item.accountId}
                                            >
                                                {item.accountName}
                                            </MenuItem>
                                        ))}
                                    </LazyFormSelect>
                                </Suspense>
                            </Grid>
                        )}
                        <ProductSelection
                            handleDraftChange={handleDraftChange}
                            initialProductId={draftCase?.productId}
                            lookUpOptions={lookUpOptions}
                            setLookUpOptions={setLookUpOptions}
                            updateFormValidation={updateFormValidation}
                            showConditionalFields={showConditionalFields}
                            setShowConditionalFields={setShowConditionalFields}
                            defaultSeverityLevel={defaultSeverityLevel}
                            selectedUserProducts={selectedUserProducts}
                        />
                        <ConditionalFormFields
                            showConditionalFields={showConditionalFields}
                            handleDraftChange={handleDraftChange}
                        />
                        <Grid item xs={12}>
                            <Suspense fallback={<FormFieldLoading />}>
                                <LazyFormSelect
                                    handleOnChange={handleDraftChange}
                                    id="language-filter-select"
                                    inputLabel="Language"
                                    labelId="language-filter-select"
                                    name="language"
                                    placeholder="Select Language *"
                                >
                                    {languages?.map((item) => (
                                        <MenuItem
                                            value={item.value}
                                            key={item.value}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </LazyFormSelect>
                            </Suspense>
                        </Grid>
                        {showConditionalFields && (
                            <Grid item xs={12}>
                                <Suspense fallback={<FormFieldLoading />}>
                                    <FormControl
                                        error={!!errors.severity}
                                        fullWidth
                                    >
                                        <InputTitle>Severity</InputTitle>
                                        <Typography
                                            component="div"
                                            sx={{
                                                cursor: 'pointer',
                                                paddingX: 1.75,
                                                paddingY: 1.875,
                                                borderRadius: 1,
                                                border: '1px solid',
                                                borderColor: 'action.disabled',
                                            }}
                                            onClick={() =>
                                                setOpenSeverity(true)
                                            }
                                        >
                                            {formValues.severity ??
                                                'Select Severity'}
                                        </Typography>
                                        {errors.severity && (
                                            <FormHelperText>
                                                {
                                                    errors.severity
                                                        ?.message as string
                                                }
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <SeveritySelectDialog
                                        defaultValue={formValues.severity}
                                        open={openSeverity}
                                        options={severityLevels || []}
                                        onConfirm={(value) => {
                                            setFieldValue('severity', value);
                                            handleDraftChange({
                                                name: 'severity',
                                                value,
                                            });
                                            setOpenSeverity(false);
                                        }}
                                        onClose={() => setOpenSeverity(false)}
                                    />
                                </Suspense>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Suspense fallback={<FormFieldLoading />}>
                                <LazyFormInput
                                    color="primary"
                                    fullWidth
                                    handleOnChange={handleDraftChange}
                                    inputLabel="Title"
                                    name="caseTitle"
                                    placeholder="Enter a brief subject line *"
                                    variant="outlined"
                                />
                            </Suspense>

                            <KBArticlesSuggestion
                                caseTitle={formValues.caseTitle}
                                productId={
                                    getSelectedProduct(formValues.productId)
                                        ?.productId
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Suspense fallback={<FormFieldLoading />}>
                                <LazyFormTextArea
                                    color="primary"
                                    fullWidth
                                    handleOnChange={handleDraftChange}
                                    inputLabel="Description"
                                    multiline
                                    name="caseDescription"
                                    placeholder="Detailed description of your request *"
                                    rows={6}
                                    size="medium"
                                    variant="outlined"
                                />
                            </Suspense>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            aria-label="Attach Documents"
                        >
                            <InputTitle>
                                Attach Supplementary Documents
                            </InputTitle>
                            <Dropzone
                                onDrop={onDrop}
                                showDropArea
                                info={MESSAGE_ZIPPING_FILES}
                                disabled={
                                    isSubmitting ||
                                    draftSaveStatus === 'deleting'
                                }
                                errors={errors.attachments?.message?.toString()}
                                files={files}
                                onRemove={hnadleRemoveFileDrop}
                                retryUpload={retryUploadFile}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container xs={12} color="text.secondary">
                            <Button
                                variant="contained"
                                disabled={actionDisable}
                                isLoading={isSubmitting}
                                type="submit"
                                sx={{ marginRight: 1 }}
                            >
                                Submit
                            </Button>
                            <DraftStatus status={draftSaveStatus} />
                            <DraftDeleteButton
                                hasDraft={!!draftData?.id}
                                disabled={actionDisable}
                                onClick={deleteDraft}
                            />
                        </Grid>
                        <Grid item container justifyContent="center" xs={12} />
                    </Grid>
                ) : (
                    <LoadingBox />
                )}
            </form>
        </FormProvider>
    );
};

export default Form;
