import { MouseEvent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@northstar/core/Typography';
import Menu from '@northstar/core/Menu';
import Divider from '@northstar/core/Divider';
import Avatar from '@northstar/core/Avatar';
import Box from '@northstar/core/Box';
import { useAuth } from 'contexts/Auth';
import { alpha, BoxProps, useTheme } from '@northstar/core/styles';
import { getAvatarName } from 'utils/getAvatarName';
import UserAccountNav from './UserAccountNav';
import GuestNav from './GuestNav';

function NavAccount({ sx, ...rest }: BoxProps) {
    const { pathname } = useLocation();
    const theme = useTheme();
    const { user } = useAuth();
    const [accountEl, setAccountEl] = useState<HTMLElement | undefined>();

    const avatarName = user && getAvatarName(user.name);

    const handleAccountClick = (event: MouseEvent<HTMLElement>): void => {
        setAccountEl(event.currentTarget);
    };

    useEffect(() => {
        setAccountEl(undefined);
    }, [pathname]);

    return user ? (
        <>
            <Box
                {...rest}
                onClick={handleAccountClick}
                aria-label="Account Navigation"
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    ...sx,
                }}
            >
                <Avatar
                    aria-label="User Avatar"
                    sx={{
                        bgcolor: alpha(theme.palette.marketing.mintGreen, 0.2),
                        color: 'common.white',
                    }}
                    alt={user?.name}
                >
                    {avatarName}
                </Avatar>
                {!user && (
                    <Typography sx={{ color: 'common.white' }} marginLeft={1}>
                        My account
                    </Typography>
                )}
            </Box>
            <Menu
                id="account-select"
                anchorEl={accountEl}
                keepMounted
                open={Boolean(accountEl)}
                onClose={() => setAccountEl(undefined)}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                PaperProps={{
                    sx: {
                        minWidth: 265,
                        maxWidth: 265,
                    },
                }}
            >
                <Box>
                    <Box
                        sx={{
                            ml: 1,
                            mr: 1.5,
                            display: 'flex',
                        }}
                    >
                        <Avatar
                            sx={{ mr: 1, bgcolor: 'primary.main' }}
                            alt={user?.name}
                        >
                            {avatarName}
                        </Avatar>
                        <Box sx={{ overflow: 'hidden' }}>
                            <Typography
                                noWrap
                                variant="body1"
                                title={user?.name}
                            >
                                {user?.name}
                            </Typography>
                            <Typography
                                title={user?.preferred_username}
                                variant="body2"
                                color="textSecondary"
                                noWrap
                            >
                                {user?.preferred_username}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <UserAccountNav />
                </Box>
            </Menu>
        </>
    ) : (
        <GuestNav
            color="inherit"
            sx={{
                background: 'white',
                color: 'black',
                '&:hover': {
                    background: 'white',
                },
                marginLeft: 2,
            }}
        />
    );
}

export default NavAccount;
