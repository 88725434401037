import DialogTitle from '@northstar/core/DialogTitle';
import DialogContent from '@northstar/core/DialogContent';
import Dialog from '@northstar/core/Dialog';
import TextField from '@northstar/core/TextField';
import { Button } from 'components/Button';
import { FormikHelpers, useFormik } from 'formik';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { object, string } from 'libs/validation';
import { useNavigate } from 'react-router-dom';
import { CaseData } from 'types/cases';
import { mutate } from 'swr';
import { useScrollToInvalidField } from 'hooks/useScrollToInvalidField';
import { useRef } from 'react';

interface Props {
    open: boolean;
    handleClose: () => void;
    caseData: CaseData;
}

interface Values {
    resolution: string;
    resolutionDescription: string;
}

const validationSchema = object({
    resolution: string().trim().required().max(200),
    resolutionDescription: string().trim().max(100000),
});

const ResolveCaseDialog = ({ open, handleClose, caseData }: Props) => {
    const navigate = useNavigate();
    const { addNotification } = useApp();
    const formItemRef = useRef<HTMLFormElement>(null);
    const initialValues = {
        resolution: '',
        resolutionDescription: '',
    };

    const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
        const payload = validationSchema.cast(values) as Values;

        try {
            actions.setSubmitting(true);

            const data = await axios.put(
                `/cases/resolve/${caseData.id}`,
                payload
            );
            if (data.status === 204) {
                actions.resetForm();
                mutate(`/cases/${caseData.id}`);
                mutate('/cases', async (prev: any) => {
                    const filteredCases =
                        prev?.cases.filter(
                            (item: any) => item.id !== caseData.id
                        ) || [];

                    return {
                        cases: [
                            { ...caseData, caseStatus: 'Resolved' },
                            ...filteredCases,
                        ],
                    };
                });
                addNotification({
                    message: 'Support request resolved successfully',
                    status: 204,
                });
            }
            handleClose();
            navigate('/support/requests');
        } catch (e: any) {
            actions.setSubmitting(false);
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const { isSubmitting, errors } = formik;

    useScrollToInvalidField({
        isSubmitting,
        errors,
        container: formItemRef.current,
    });

    const dialogCloseHandler = (reason?: string) => {
        if (
            (formik.isSubmitting && reason === 'backdropClick') ||
            reason === 'escapeKeyDown'
        ) {
            return false;
        }
        formik.resetForm();
        handleClose();
        return true;
    };

    return (
        <Dialog
            id="resolution-dialog"
            open={open}
            onClose={(_, reason) => dialogCloseHandler(reason)}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
        >
            <form onSubmit={formik.handleSubmit} ref={formItemRef}>
                <DialogTitle id="form-dialog-title">
                    Resolve Request
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="resolution"
                        name="resolution"
                        label="Resolution*"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        fullWidth
                        value={formik.values.resolution}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.resolution &&
                            Boolean(formik.errors.resolution)
                        }
                        helperText={
                            formik.touched.resolution &&
                            formik.errors.resolution
                        }
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        margin="dense"
                        id="resolutionDescription"
                        name="resolutionDescription"
                        label="Description of Resolution"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        value={formik.values.resolutionDescription}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.resolutionDescription &&
                            Boolean(formik.errors.resolutionDescription)
                        }
                        helperText={
                            formik.touched.resolutionDescription &&
                            formik.errors.resolutionDescription
                        }
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                        isLoading={formik.isSubmitting}
                        sx={{ mr: 1 }}
                    >
                        RESOLVE
                    </Button>
                    <Button
                        onClick={() => dialogCloseHandler()}
                        disabled={formik.isSubmitting}
                    >
                        CANCEL
                    </Button>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ResolveCaseDialog;
