import { ContactFooter } from 'components/CallToAction/ContactFooter';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import type { Product, Manuals } from 'types/products';
import LoadingBox from 'components/LoadingBox';
import { Main } from 'components/layouts';
import { useTheme } from '@northstar/core/styles';
import List from '@northstar/core/List';
import ListItem from '@northstar/core/ListItem';
import Typography from '@northstar/core/Typography';
import Grid from '@northstar/core/Grid';
import { Container } from 'components/Container';
import Tooltip from '@northstar/core/Tooltip';
import Zoom from '@northstar/core/Zoom';
import { withAuth } from 'components/Auth';
import Box from '@northstar/core/Box';
import { MouseEvent, useState } from 'react';
import { useAuth } from 'contexts/Auth';
import AccessRestricted from 'components/DataState/AccessRestricted';
import { ProductDownloadableLinks } from './ProductDownloadableLinks';

const ProductDocumentationPage = () => {
    const { productSlug } = useParams();
    const [toolTipId, setToolTipId] = useState<string | null>();

    const { getProductPermissions } = useAuth();

    const theme = useTheme();

    const { data: product, error: productSlugError } = useSWR<Product>(
        `/products/slug/${productSlug}`
    );

    const { permissions, isSecured } = product || {};

    const { canManuals } = getProductPermissions(permissions, isSecured);

    const { data: manuals, error: manualsError } = useSWR<Manuals[]>(
        canManuals && product && `/manuals/product/${product.id}`
    );

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        ...(product
            ? [
                  {
                      href: `/${product.slug}`,
                      title: product.name,
                      isActive: false,
                  },
                  {
                      title: 'Documentation',
                      isActive: true,
                  },
              ]
            : []),
    ];

    const handleMouseEnter = (
        currentTarget: HTMLParagraphElement,
        id: string
    ) => {
        if (currentTarget.scrollHeight > currentTarget.clientHeight) {
            setToolTipId(id);
        }
    };

    const handleMouseLeave = () => setToolTipId(null);

    const checkForEmptyRow = (item: Manuals) =>
        !item.chmLink &&
        !item.exeLink &&
        !item.htmlLink &&
        !item.pdfLink &&
        !item.zipLink;

    const errorStatus = (productSlugError || manualsError)?.response?.status;

    return (
        <Main breadcrumbs={breadcrumbs} errorStatus={errorStatus}>
            {(!product || (canManuals && !manuals)) && (
                <LoadingBox minHeight={400} />
            )}
            {canManuals === false && <AccessRestricted />}
            {manuals && (
                <Container>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {product?.name} Documentation
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{ fontWeight: 'normal' }}
                        >
                            From helpful step-by-step how tos to solutions to
                            error messages, you will find the resource you need.
                        </Typography>
                    </Grid>
                    {manuals.length > 0 && (
                        <List
                            sx={{
                                width: '100%',
                                marginTop: 5,
                            }}
                        >
                            {manuals.map((item, index) => {
                                const isEmptyRow = checkForEmptyRow(item);

                                const idTitle = `t-${index}-${item.id}`;
                                const idDescription = `d-${index}-${item.id}`;

                                return isEmptyRow ? null : (
                                    <ListItem
                                        key={item.id}
                                        alignItems="center"
                                        divider
                                        sx={{
                                            flexBasis: '20%',
                                            paddingY: 2,
                                            '& p': {
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                            },
                                            '& a:not(:last-of-type)': {
                                                borderRight: `1px solid ${theme.palette.text.secondary}`,
                                            },
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            title={item.title}
                                            TransitionComponent={Zoom}
                                            open={toolTipId === idTitle}
                                        >
                                            <Typography
                                                onMouseEnter={({
                                                    currentTarget,
                                                }: MouseEvent<HTMLParagraphElement>) =>
                                                    handleMouseEnter(
                                                        currentTarget,
                                                        idTitle
                                                    )
                                                }
                                                onMouseLeave={handleMouseLeave}
                                                sx={{ flexBasis: '20%' }}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {item.title}
                                            </Typography>
                                        </Tooltip>

                                        <Box
                                            sx={{
                                                flexBasis: '40%',
                                                paddingLeft: 2,
                                                paddingRight: 4,
                                            }}
                                        >
                                            <Tooltip
                                                arrow
                                                title={item.description}
                                                TransitionComponent={Zoom}
                                                open={
                                                    toolTipId === idDescription
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    onMouseEnter={({
                                                        currentTarget,
                                                    }: MouseEvent<HTMLParagraphElement>) =>
                                                        handleMouseEnter(
                                                            currentTarget,
                                                            idDescription
                                                        )
                                                    }
                                                    onMouseLeave={
                                                        handleMouseLeave
                                                    }
                                                >
                                                    {item.description}
                                                </Typography>
                                            </Tooltip>
                                        </Box>

                                        <ProductDownloadableLinks item={item} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </Container>
            )}
            <ContactFooter />
        </Main>
    );
};

export default withAuth(ProductDocumentationPage);
