import { useAuth } from 'contexts/Auth';
import useSWR from 'swr';
import {
    AnswerOption,
    QuestionCriteria,
    QuestionDefinition,
} from 'types/createCase';

const useServiceRequest = () => {
    const { user } = useAuth();

    const { canCreateServiceRequests } = user || {};

    const { data: questionCriteria } = useSWR<QuestionCriteria[]>(
        canCreateServiceRequests && '/service_requests/questions_criteria'
    );

    const { data: questionDefinitions } = useSWR<QuestionDefinition[]>(
        canCreateServiceRequests && '/service_requests/question_definitions'
    );

    const { data: answerOptions } = useSWR<AnswerOption[]>(
        canCreateServiceRequests && '/service_requests/answer_options'
    );

    const isServiceRequestDataLoaded = !!(
        questionCriteria &&
        questionDefinitions &&
        answerOptions
    );

    return {
        answerOptions,
        questionCriteria,
        questionDefinitions,
        isServiceRequestDataLoaded,
    };
};

export { useServiceRequest };
