import { useTheme } from '@northstar/core/styles';
import Box from '@northstar/core/Box';
import Grid from '@northstar/core/Grid';
import Typography from '@northstar/core/Typography';
import { Link } from 'components/Link';
import { Button } from 'components/Button';
import NoData from 'components/DataState';
import SearchOff from 'components/Icons/SearchOff';
import SanitizeHTML from 'components/SanitizeHTML';
import { SearchResultType } from 'types/searchResult';
import LoadingData from './LoadingData';

interface Props {
    data: SearchResultType;
    term: string | null;
    isLoading: boolean;
    handleNextLink: (next: string | null) => void;
    isLoadingMore: boolean;
}

const SearchResult = ({
    data,
    term,
    isLoading,
    handleNextLink,
    isLoadingMore,
}: Props) => {
    const theme = useTheme();

    return (
        <Box>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                flex="1 1 100%"
                justifyContent={{ sm: 'space-between' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                borderBottom={`1px solid ${theme.palette.divider}`}
                paddingBottom={3}
            >
                <Box>
                    <Typography variant="h4" component="p">
                        Search results
                    </Typography>
                    <Typography
                        color="textSecondary"
                        marginBottom={{ xs: 1, sm: 0 }}
                    >
                        {term
                            ? `Results for “${term}”`
                            : 'Enter a keyword or phrase in the search field above'}
                    </Typography>
                </Box>
            </Box>
            {isLoading &&
                Array.from(Array(5).keys()).map((index) => (
                    <LoadingData key={index} />
                ))}
            {!isLoading && data?.hits && (
                <>
                    {data.hits.length === 0 ? (
                        <NoData
                            marginTop={10}
                            title="No results found"
                            description="Enter a keyword or phrase in the search field above."
                            icon={SearchOff}
                        />
                    ) : (
                        <Grid>
                            {data.hits.map((item) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={item.knowledgeArticleId}
                                    sx={{
                                        py: 3,
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                    }}
                                >
                                    <Link
                                        variant="h6"
                                        color="primary"
                                        to={`/${item.productSlug}/kb-articles/${item.urlKey}`}
                                    >
                                        {item.title}
                                    </Link>
                                    {item?.highlight?.content?.length > 0 && (
                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                            aria-label="Search Content"
                                        >
                                            <SanitizeHTML
                                                component="span"
                                                html={item?.highlight?.content[0]
                                                    .replaceAll(
                                                        '{crmhit}',
                                                        '<b>'
                                                    )
                                                    .replaceAll(
                                                        '{/crmhit}',
                                                        '</b>'
                                                    )}
                                            />
                                        </Typography>
                                    )}

                                    <Typography
                                        color="textSecondary"
                                        marginTop={{ xs: 1, sm: 0.5 }}
                                    >
                                        {item.productBrandName &&
                                            `${item.productBrandName} / `}
                                        {item.productName &&
                                            `${item.productName} / `}
                                        {item.type && `${item.type}`}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    {data.hits.length > 0 && data.nextLink && (
                        <Box
                            paddingTop={5}
                            display="flex"
                            justifyContent="center"
                            onClick={() => handleNextLink(data.nextLink)}
                            aria-label="Load More Container"
                        >
                            <Button
                                variant="contained"
                                isLoading={isLoadingMore}
                                disabled={isLoadingMore}
                            >
                                Load more
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default SearchResult;
