import { Skeleton } from '@northstar/core';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { NoProducts } from 'components/DataState/NoProducts';
import { Link } from 'components/Link';

import type { Brand } from 'types/products';

type Props = {
    data: Brand[] | undefined;
};

const ProductsList = ({ data }: Props) => {
    if (!data) {
        return (
            <Box sx={{ marginTop: '1.5rem', columnCount: 3 }}>
                {Array.from(Array(3).keys()).map((index) => {
                    return (
                        <Box key={index}>
                            <Skeleton
                                aria-label="skeleton"
                                width="80%"
                                height={20}
                            />
                            <Skeleton
                                aria-label="skeleton"
                                width="80%"
                                animation="wave"
                                height={20}
                                sx={{ marginLeft: '1rem' }}
                            />
                            <Skeleton
                                aria-label="skeleton"
                                width="80%"
                                animation="wave"
                                height={20}
                                sx={{ marginLeft: '1rem' }}
                            />
                        </Box>
                    );
                })}
            </Box>
        );
    }

    if (data?.length === 0) {
        return <NoProducts marginTop={4} />;
    }

    return (
        <Box sx={{ mt: 2, columnCount: 3 }}>
            {data.map((brandItem) =>
                brandItem.productList.length === 0 ? null : (
                    <Box
                        key={brandItem.id}
                        sx={{
                            mb: 3,
                            breakInside: 'avoid',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h3"
                            sx={{
                                fontWeight: 'normal',
                                wordBreak: 'break-all',
                            }}
                        >
                            {brandItem.name}
                        </Typography>
                        {brandItem.productList.map((brandProductItem) => {
                            return brandProductItem.name &&
                                brandProductItem.slug ? (
                                <Link
                                    key={brandProductItem.id}
                                    variant="body1"
                                    to={`/${brandProductItem.slug}`}
                                    sx={{
                                        ml: 2,
                                        mt: 1,
                                        display: 'block',
                                    }}
                                >
                                    {brandProductItem.name}
                                </Link>
                            ) : null;
                        })}
                    </Box>
                )
            )}
        </Box>
    );
};

export { ProductsList };
