import { CaseData } from 'types/cases';
import { ColumnDef, createColumnHelper } from '@tanstack/table-core';
import { CaseModifyDateColumn } from './CaseModifyDateColumn';
import { CaseCreateDateColumn } from './CaseCreateDateColumn';
import { CaseStatusColumn } from './CaseStatusColumn';
import { CaseTitleColumn } from './CaseTitleColumn';
import { CreatedByColumn } from './CreatedByColumn';
import { ProductColumn } from './ProductColumn';
import { RequestNumberColumn } from './RequestNumberColumn';
import { CaseAssigneeColumn } from './CaseAssigneeColumn';
import { OrganizationColumn } from './OrganizationColumn';
import { CaseSeverityColumn } from './CaseSeverityColumn';
import { CaseAttachmentsColumn } from './CaseAttachmentsColumn';

type Props = {
    isSingleAccount: boolean;
};

const getHeaderColumns = ({ isSingleAccount }: Props) => {
    const columnHelper = createColumnHelper<CaseData>();

    const columns = [
        CaseTitleColumn(columnHelper),
        CaseStatusColumn(columnHelper),
        CaseSeverityColumn(columnHelper),
        RequestNumberColumn(columnHelper),
        ProductColumn(columnHelper),
        ...(isSingleAccount ? [] : [OrganizationColumn(columnHelper)]),
        CreatedByColumn(columnHelper),
        CaseAssigneeColumn(columnHelper),
        CaseAttachmentsColumn(columnHelper),
        CaseCreateDateColumn(columnHelper),
        CaseModifyDateColumn(columnHelper),
    ] as ColumnDef<CaseData>[];

    return columns;
};

export { getHeaderColumns };
